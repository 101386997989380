.search-results {
  position: absolute;
  z-index: 1000; /* Make sure it's above other content */
  width: 100%;
  background: white;
  max-height: 200px; /* Adjust based on your preference */
  overflow-y: auto; /* Makes it scrollable */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: adds some shadow for better visibility */ 
}

.search-result-item {
  cursor: pointer;
}

.dark-mode-results {
  background: #333; /* Dark background for dark mode */
  color: #e0e0e0; /* Light text color for dark mode */
}

/* Ensure the search result items are also styled appropriately */
.dark-mode-results .search-result-item {
  color: #e0e0e0; /* Light text color for visibility */
}

/* Optionally, add hover styling for better UX */
.dark-mode-results .search-result-item:hover {
  background-color: #444; /* Slightly lighter background on hover */
}