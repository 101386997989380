.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; /* Ensure the navbar is above other elements */
}

.nav-spacer {
  height: 100px; /* Adjust this value based on the height of your navbar */
}

body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

body.dark-mode .navbar,
body.dark-mode .news-item {
  background-color: #333;
  color: #e0e0e0;
}
