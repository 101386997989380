.favorite-stocks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  flex-direction: row;
}

.remove-button {
  font-size: 1rem; 
  color: black; 
  background-color: transparent !important; /* Keep the background transparent */
  border-color: transparent; /* Make the border transparent */
  margin-left: 10px; /* Add some space between the 'X' and the company name */
}

.remove-button:hover {
  color: black !important; /* Keep the 'X' black on hover */
  background-color: transparent !important; /* Keep the background transparent on hover */
}

.favorite-stock-box {
  max-width: 300px;
  padding: 8px; /* Add some padding around the content */
}

.alert-info h3 {
  font-size: 1rem;
}

.favorite-stock {
  display: flex;
  align-items: center;
}
